<template>
  <div>
    <titleBar
      :title="'Context informations '"
      :img="user?.avatar"
      back="auto"
      :showBackArrowDesktop="true"
      subtitle=""
      :centered="false"
      theme="white"
      :showSignup="false"
      containerClass="donotuse"
      :showDesktop="true"
      :showAfterScroll="0"
      :scrollTopOnClick="true"
      :inline="false"
      actionButtonStyle="round"
      actionButtonRoundBg="transparent"
      iconPack="fas"
    />

    <div class="sectionNA">
      <div class="containerNA NHAw800">
        <b-field horizontal label=" ">
          <header>
            <h2 class="title is-2">
              <span>AI context</span>
            </h2>
            <p>
              Personalize AI responses using custom data. This information stays private and only relevant informations
              are shared with each AIs.
            </p>
          </header>
        </b-field>

        <hr />

        <!-- START-->

        <b-field horizontal :label="c.label" v-for="c in cons" :key="c.label">
          <b-input
            size="is-"
            :placeholder="c.placeholder"
            v-model="user.profile[c.name]"
            :loading="posting"
            :disabled="posting"
            :type="c.type || 'text'"
          ></b-input>
        </b-field>

        <b-field horizontal label="Gender">
          <b-select v-model="user.profile.gender" :disabled="posting">
            <option value="undisclosed">Undisclosed</option>
            <option value="male">Male</option>
            <option value="female">Female</option>

            <option value="non-binary">Non-binary</option>
            <!--  
            <option value="examples">Examples</option> -->
            <option value="other">Other</option>
          </b-select>
        </b-field>

        <!-- END     
    -->
        <b-field horizontal label="   ">
          <b-button type="is-info is-outlined" @click="save" :loading="posting" :disabled="posting"
            >Save changes</b-button
          >
        </b-field>

        <hr />
      </div>
    </div>
  </div>
</template>

<script>
import Editor from "@/components/e/EditorV";

export default {
  //name: "readmeEditor",
  components: {
    //Editor,
  },
  props: {
    user: {
      type: Object,
      default: null,
    },
    posting: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      // readmeJson: defaultReadme, // "432432432",
    };
  },
  computed: {
    cons() {
      /*
 NAME: user.handle || user.handle,
    AGE: user.profile?.age || 30,
    GENDER: user.profile?.gender || "male",
    USER_COUNTRY: user.profile?.country || ctx.userCountry || "US",
    PASSPORT_COUNTRY:
      user.profile?.passportCountry || user.profile?.country || ctx.userCountry,
   */

      const cons = [
        {
          name: "name",
          label: "Name",
          placeholder: "ex: Jane",
          type: "text",
        },
        {
          name: "age",
          label: "Age",
          placeholder: "ex: 88",
          type: "text",
        },
        {
          name: "country",
          label: "Country",
          placeholder: "ex: Thailand",
          type: "dropdown",
        },
        /*{
          name: "gender",
          label: "Gender",
          placeholder: "Your gender",
          type: "dropdown",
        },*/
        {
          name: "passportCountry",
          label: "Passport",
          info: "For travel advices",
          placeholder: "ex: Japanese",
          type: "text",
        },
      ];

      var ccc = cons.map((c) => {
        // c.contextVars = this.user[c.name];
        return c;
      });
      return ccc;
    },
  },
  methods: {
    save() {
      this.$emit("save");
    },
  },
};
</script>

<style></style>
